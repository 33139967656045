@import '../fonts/poppins.scss';
@import '../fonts/courgette.scss';
@import 'constants.module.scss';
@import 'helpers.scss';
@import 'reset.scss';

html {
    overflow-y: auto;
	overscroll-behavior-y: none;
	overscroll-behavior-x: none;
	box-sizing: border-box;
}
* {
    font-family: $family-primary;
	box-sizing: border-box;
}
body {
	margin: 0;
	font-weight: $weight-normal;
	background-color: $black;
	box-sizing: border-box;
	color: $white;
}
#root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	main {
		flex: 1;
	}
}
.reCaptchaHolder {
  	font-family: $family-primary;
}
.container {
	flex-grow: 1;
	margin: 0 auto;
	position: relative;
	width: auto;
	@media screen and (min-width: $desktop) and (max-width: $desktopMaxW) {
		max-width: $desktop - $container-offset;
		padding-inline: 2rem;
	}
	@media screen and (min-width: $widescreen) and (max-width: $widescreenMaxW) {
		max-width: $widescreen - $container-offset;
		padding-inline: 2rem;
	}
	@media screen and (min-width: $desktop) {
		max-width: $fullhd - $container-offset;
	}
}