$font-path: "/fonts/poppins" !default;

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Poppins Regular'),
    local('Poppins-Regular'),
    url('#{$font-path}/Poppins-Regular.woff2') format('woff2'),
    url('#{$font-path}/Poppins-Regular.woff') format('woff')
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Poppins Bold'),
    local('Poppins-Bold'),
    url('#{$font-path}/Poppins-Bold.woff2') format('woff2'),
    url('#{$font-path}/Poppins-Bold.woff') format('woff')
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local('Poppins Black'),
    local('Poppins-Black'),
    url('#{$font-path}/Poppins-Black.woff2') format('woff2'),
    url('#{$font-path}/Poppins-Black.woff') format('woff')
}