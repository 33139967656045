.is-family-secondary {
	font-family: $family-secondary;
}
.nowrap {
    white-space: nowrap;
}
.m-t-0 {
	margin-top: 0rem;
}
.m-b-0 {
	margin-bottom: 0rem;
}
.m-l-0 {
	margin-left: 0rem;
}
.m-r-0 {
	margin-right: 0rem;
}
.m-t-1 {
	margin-top: 1rem;
}
.m-b-1 {
	margin-bottom: 1rem;
}
.m-l-1 {
	margin-left: 1rem;
}
.m-r-1 {
	margin-right: 1rem;
}
.m-t-2 {
	margin-top: 2rem;
}
.m-b-2 {
	margin-bottom: 2rem;
}
.m-l-2 {
	margin-left: 2rem;
}
.m-r-2 {
	margin-right: 2rem;
}
.m-l-half-rem {
	margin-left: 0.5rem;
}
.m-r-half-rem {
	margin-right: 0.5rem;
}
.mx-auto {
	margin: 0 auto;
}
.is-marginless {
	margin: 0;
}
.w-100 {
	width: 100%;
}
.is-size-1 {
	font-size: 3rem !important;
}

.is-size-2 {
	font-size: 2.5rem !important;
}

.is-size-3 {
	font-size: 2rem !important;
}

.is-size-4 {
	font-size: 1.5rem !important;
}

.is-size-5 {
	font-size: 1.25rem !important;
}
.is-size-5-half {
	font-size: 1.125rem;
}
.is-size-6 {
	font-size: 1rem;
}
.has-width-fit-content {
	width: fit-content;
}
.has-text-centered {
	text-align: center;
}
.has-text-left {
	text-align: left;
}
.has-text-right {
	text-align: right;
}
.has-text-weight-normal {
	font-weight: $weight-normal;
}
.has-text-weight-bold {
	font-weight: $weight-bold;
}
.has-text-green {
	color: $green;
}
.has-text-blue {
	color: $blue;
}
.has-text-black {
	color: $black;
}
.is-relative {
	position: relative;
}
.is-block {
	display: block;
}
.is-flex {
	display: flex;
}
.flex-start {
	justify-content: flex-start !important;
}
.flex-end {
	justify-content: flex-end !important;
}
.align-center {
	align-items: center;
}
.align-start {
	align-items: flex-start;
}
.align-end {
	align-items: end;
}
.justify-center {
	justify-content: center !important;
}
.flex-column {
	flex-direction: column;
}
.justify-between {
	justify-content: space-between;
}
.justify-around {
	justify-content: space-around;
}
.justify-end {
	justify-content: flex-end;
}
.align-self-end {
	align-self: flex-end;
}
.row-wrap {
	flex-flow: row wrap;
}
.flex-justify-align {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.flex-wrap {
	flex-wrap: wrap;
}
.flex-1 {
	flex: 1 0;
}
.flex-2 {
	flex: 2 0;
}
.flex-3 {
	flex: 3 0;
}
@keyframes spinAround {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
.help {
	font-size: 0.875rem;
	margin-top: 0.25rem;
}
.has-text-danger {
	color: $danger;
}
.has-text-success {
	color: $success;
}
.field {
	&:not(:last-child) {
		margin-bottom: 0.75rem;
	}
	.label {
		margin-bottom: 0.5rem;
		display: block;
	}
	input, select, textarea {
		margin-bottom: 0;
	}
}
.is-hidden {
	display: none;
}
.is-hidden-touch {
	@media all and (max-width: $tabletMaxW) {
		display: none !important;
	}
}
.is-hidden-mobile {
	@media all and (max-width: $mobileMaxW) {
		display: none !important;
	}
}
.is-hidden-desktop {
	@media all and (min-width: $desktop) {
		display: none !important;
	}
}
.has-cursor-pointer {
	cursor: pointer;
}

.link-underline {
	position: relative;
	color: $white;
	cursor: pointer;
	text-decoration: none;
	&.is-active {
		color: $green;
		font-weight: $weight-bold;
	}
	&:not(.is-active) {
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			transform: scaleX(1);
			transform-origin: center;
			width: 100%;
			height: 1px;
			background: currentColor;
			transition: transform 0.3s ease;
		}
	
		@media (hover: hover) {
			&:hover {
				&::after {
					transform: scaleX(0);
					transform-origin: center;
				}
			}
		}
	}
	
}
.section-title {
	font-size: 4rem;
	margin-bottom: 2rem;
	.title-box {
		padding: 0 1rem;
		background-color: $white;
		color: $black;
		font-weight: $weight-bold;
		position: relative;
	}
	@media all and (max-width: $tabletMaxW) {
		font-size: 2.5rem;
	}
}
.section-subtitle {
	font-family: $family-secondary;
	font-size: 3rem;
	margin-bottom: 2rem;
	@media all and (max-width: $tabletMaxW) {
		font-size: 1.75rem;
	}
}

.ceo {
	&::before {
		content: "Andrzej Krzysztof";
	}
}
.firm-name {
	&::before {
		content: "Real ";
		color: $green;
	}
	&::after {
		content: "Media";
		color: $blue;
	}
}
.ltd {
	&::before {
		content: "ltd.";
	}
}
.firn-name-full {
	&::before {
		content: "Real Media ltd."
	}
}
.firm-address {
	&:before {
		content: "32 Hristo Botev str. \a Burgas 8000, \a";
		white-space: pre;
	}
	&:after {
		content: "Bulgaria";
		white-space: pre;
		font-weight: $weight-bold;
	}
}
.firm-phone {
	&:before {
		content: "+420 602 854 921"
	}
}
.firm-email {
	&:before {
		content: "admin@realmedia.bg"
	}	
}