$font-path-courgette: '/fonts/courgette' !default;

@font-face {
	font-family: 'Courgette';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Courgette Regular'),
	local('Courgette-Regular'),
	url('#{$font-path-courgette}/Courgette-Regular.woff2') format('woff2'),
	url('#{$font-path-courgette}/Courgette-Regular.woff') format('woff')
}