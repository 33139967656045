h1, h2, h3 {
    margin: 0;
    font-weight: $weight-normal;
    font-size: 1rem;
}
a {
    text-decoration: none;
    cursor: pointer;
    color: $white;
    &.has-text-black {
        color: $black;
    }
}
p {
    margin-top: 0;
}
ul {
    list-style: none;
    padding: 0;
}
input, textarea, select {
    font-family: $family-primary;
    font-size: 1rem;
    width: 100%;
    display: block;
    padding: 0.5rem 0;
    margin-bottom: 0.5rem;
    background-color: transparent;
    border-bottom: 1px solid $white;
    border-left: none;
    border-right: none;
    border-top: none;
    transition: all 0.2s;
    &:focus {
      	outline: none;
    }
    &::placeholder {
      	font-size: 1.125rem;
        color: $white;
    }
    &.is-danger {
      	border-color: $danger;
    }
    &:hover {
        border-color: $black;
    }
}
select {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: none;
}
.button {
    color: $white;
    border: 2px solid $white;
    border-radius: $radius 0 $radius 0;
    padding: 0.75rem 1.5rem;
    transition: all 0.2s;
    cursor: pointer;
    background: none;
    font-size: 1rem;
    &:hover {
        background-color: $white;
        color: $black;
        border-radius: 0;
    }
}
