$weight-normal: 400;
$weight-bold: 700;

$family-primary: 'Poppins', sans-serif;
$family-secondary: 'Courgette', sans-serif;

//Radiuses
$radius-extra-small: 5px;
$radius-small: 10px;
$radius: 15px;
$radius-large: 20px;


//Media Sizes
$gap: 32px;
$quad2Hd: 1682px;
$quadHd: 1442px;
$desktop: 1024px;
$widescreen: 1152px + (2 * $gap);
$fullhd: 1344px + (2 * $gap);
$mobileMaxW: 767px;
$tablet: $mobileMaxW + 1;
$tabletMaxW: $desktop - 1px;
$desktopMaxW: $widescreen - 1px;
$widescreenMaxW: $fullhd - 1px;
$fullHdMaxW: $quadHd - 1px;
$quadHdMaxW: $quad2Hd - 1px;
$container-offset: (2 * $gap) !default;


//Colors
$white: #FFF;
$black: #000;
$grey: #414141;
$green: #0FFFAB;
$blue: #7175FF;
$macaroni-and-cheese: #FFB871;
$dark-tangerine: #FF9911;
$pale-magenta: #FF70E4;
$lawn-green: #75FF10;
$french-pink: #FF709D;
$danger: #ff3860;
$success: $green;
$primary: $danger;

$header-height: 4.5rem;;

:export {
    white: $white;
    black: $black;
    grey: $grey;
    danger: $danger;
    green: $green;
    blue: $blue;
    success: $success;
    macaroniAndCheese: $macaroni-and-cheese;
    darkTangerine: $dark-tangerine;
    paleMagenta: $pale-magenta;
    lawnGreen: $lawn-green;
    frenchPink: $french-pink;
    quadHD: $quadHd;
    quad2HD: $quad2Hd;
    mobileMaxW: $mobileMaxW;
    tablet: $tablet;
    tabletMinW: $mobileMaxW + 1;
    tabletMaxW: $tabletMaxW;
    desktop: $desktop;
    desktopMaxW: $desktopMaxW;
    widescreen: $widescreen;
    widescreenMaxW: $widescreenMaxW;
    fullhd: $fullhd;
    fullHdMaxW: $fullHdMaxW;
    quadHdMaxW: $quadHdMaxW;
    weightNormal: $weight-normal;
    weightBold: $weight-bold;
    radiusExtraSmall: $radius-extra-small;
    radiusSmall: $radius-small;
    radius: $radius;
    radiusLarge: $radius-large;
    headerHeight: $header-height;
};